import { createMeta } from '@/data/meta/utils';

const image = '/img/pages/loldocup/LDC_thum.png';
const keywords = '라이엇 게임즈 아마추어 리그, 라이엇 아마추어 리그, 레벨업 라이엇, 레벨업 , 레벨업 LoL, 레벨업 라이엇게임즈, 롤 아마추어리그, 리그오브레전드 아마추어 리그, 롤 아마추어, e스포츠 아마추어 대회, 이스포츠 아마추어 대회, 아마추어 대회, 레벨업 대회, League of Legends, 2021 롤 도네이션 컵 , 리그오브레전드 아마추어 리그, 리그오브레전드 아마추어 대회, 리그오브레전드 대회, 리그오브레전드 리그, 리그오브레전드 공식 대회, 리그오브레전드 아마추어 대회, 리그오브레전드 레벨업, 라이엇 리그오브레전드, 라이엇 리그오브레전드 대회, 리그오브레전드 롤 도네이션  컵, 리그오브레전드 대회, 리그오브레전드, League of Legends 대회, LoL, LoL 대회,  League of Legends 아마추어 대회, LoL 아마추어 대회, League of Legends 아마추어 리그, LoL 아마추어 리그, 라이엇 League of Legends, 라이엇 공식 대회, 라이엇 공식 아마추어 대회, 라이어 공식 아마추어 리그, 공식 롤 대회, 공식 롤 리그';

export default {
  loldocupHome: {
    title: '[롤 도네이션 컵] 대회 신청',
    ...createMeta({
      title: '[롤 도네이션 컵] 대회 신청',
      description:
        '모일수록 행복해지는 협곡 토너먼트! 기부금을 적립하고 스타 애장품까지 획득하는  LoL 연말 대축제에 참여해 보세요.',
      keywords,
      image,
    }),
  },
  loldocupLeagueGuide: {
    title: '[롤 도네이션 컵] 대회 안내',
    ...createMeta({
      title: '[롤 도네이션 컵] 대회 안내',
      description: '총 상금 2,000만원과 기부자 대표 자격까지! LCK프로, 연예인, 인플루언서 그리고 아마추어가 함께하는 연말 공식 대회에 참여해 보세요.',
      keywords,
      image,
    }),
  },
  loldocupApplyGift: {
    title: '[롤 도네이션 컵] 애장품 응모',
    ...createMeta({
      title: '[롤 도네이션 컵] 애장품 응모',
      description: '대회에 참가하고 내가 좋아하는 스타의 애장품도 응모해 보세요.',
      keywords,
      image,
    }),
  },
  loldocupLeagueResult: {
    title: '[롤 도네이션 컵] 대회 결과',
    ...createMeta({
      title: '[롤 도네이션 컵] 대회 결과',
      description: '대회 결과를 확인해 보세요.',
      keywords,
      image,
    }),
  },
  newsFeed: {
    title: '[롤 도네이션 컵] 공지/이벤트',
    ...createMeta({
      title: '[롤 도네이션 컵] 공지/이벤트',
      description: '대회 관련 공지 및 이벤트 내용을 확인해 보세요.',
      keywords,
      image,
    }),
  },
};
