import { createMeta } from '@/data/meta/utils';

const image = '/img/asset/WariWari_SEO.jpg';
const keywords = '라이엇 게임즈 아마추어 리그, 라이엇 아마추어 리그, 레벨업 라이엇, 레벨업 롤, 레벨업 LoL, 레벨업 라이엇게임즈, 롤 아마추어리그, 리그오브레전드 아마추어 리그, 롤 아마추어, e스포츠 아마추어 대회, 이스포츠 아마추어 대회, 아마추어 대회, 레벨업 대학, 2021 WR 와리와리 대난투, 와일드리프트 아마추어 리그, 와일드리프트 아마추어 대회, 와일드리프트 대회, 와일드리프트 리그, 와일드 리프트 공식 대회, 와일드 리프트 아마추어 대회, 와일드 리프트 레벨업, 라이엇 와일드리프트, 라이엇 와일드리프트 대회, 와일드리프트 와리와리 대난투, 와리와리 대난투 대회';

export default {
  wrHome: {
    title: '[와리와리 대난투] 대회 신청',
    ...createMeta({
      title: '[와리와리 대난투] 대회 신청',
      description:
        '와일드리프트 한국대표 선발전 진출 시드권과 다양한 보상이 함께하는 와리와리 대난투에 참여해 보세요.',
      keywords,
      image,
    }),
  },
  newsFeed: {
    title: '[와리와리 대난투] 공지/이벤트',
    ...createMeta({
      title: '[와리와리 대난투] 공지/이벤트',
      description: '대회 관련 공지 및 이벤트 내용을 확인하실 수 있습니다.',
      keywords,
      image,
    }),
  },
  WariwariLanding: {
    title: '[와리와리 대난투] 대회 안내',
    ...createMeta({
      title: '[와리와리 대난투] 대회 안내',
      description:
        '와리와리 대난투에 참여하여 총상금 2,500만원과 한국대표 선발전 진출 시드권을 잡아보세요.',
      keywords,
      image,
    }),
  },
};
