import { createMeta } from '@/data/meta/utils';

const image = '/img/pages/end/meta/TFT_meta_guide.jpg';
const keywords = '라이엇 게임즈 아마추어 리그, 라이엇 아마추어 리그, 레벨업 라이엇, 레벨업 , 레벨업 TFT, 레벨업 라이엇게임즈, 티에프티 아마추어리그, 전략적팀전투 아마추어 리그, 티에프티 아마추어, e스포츠 아마추어 대회, 이스포츠 아마추어 대회, 아마추어 대회, 레벨업 대회, 전략적팀전투 아마추어 리그, 전략적팀전투 아마추어 대회, 전략적팀전투 대회, 전략적팀전투 리그, 전략적팀전투 공식 대회, 전략적팀전투 아마추어 대회, 전략적팀전투 레벨업, 라이엇 전략적팀전투, 라이엇 전략적팀전투 대회, 전략적팀전투 대회, 전략적팀전투, Team Fight Tacricss 대회, TFT, TFT 대회, Team Fight Tacricss 아마추어 대회, TFT 아마추어 대회, Team Fight Tacricss 아마추어 리그, TFT 아마추어 리그, 라이엇 Team Fight Tacrics, 라이엇 공식 대회, 라이엇 공식 아마추어 대회, 라이엇 공식 아마추어 리그, 공식 롤 대회, 공식 롤 리그, 롤체, 롤토체스, 롤체 대회, 롤토체스 대회, 롤체 커뮤니티, 롤토체스 커뮤니티, 롤체 디스코드, 롤토체스 디스코드, TFT 커뮤니티 대회, TFT 커뮤니티, 라이엇 게임즈 커뮤니티, 게임 커뮤니티, TFT 디스코드';

export default {
  tftEnd: {
    title: '[전략적 팀 전투] 대회 신청',
    ...createMeta({
      title: '[전략적 팀 전투] 대회 신청',
      description: '모두가 즐기는 TFT 아마추어 리그 \n'
        + '다양한 대회와 커뮤니티가 기다립니다!',
      keywords,
      image,
    }),
  },
  newsFeed: {
    title: '[전략적 팀 전투] 공지/이벤트',
    ...createMeta({
      title: '[전략적 팀 전투] 공지/이벤트',
      description: '대회 관련 공지 및 이벤트 내용을 확인해 보세요.',
      keywords,
      image,
    }),
  },
};
