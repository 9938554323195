import { createMeta } from '@/data/meta/utils';

const image = '/img/pages/end/meta/VAL_meta_guide.jpg';
const keywords = '라이엇 게임즈 아마추어 리그, 라이엇 아마추어 리그, 레벨업 라이엇, 레벨업 , 레벨업 LoL, 레벨업 라이엇게임즈, 롤 아마추어리그, 리그오브레전드 아마추어 리그, 롤 아마추어, e스포츠 아마추어 대회, 이스포츠 아마추어 대회, 아마추어 대회, 레벨업 대회, League of Legends,발로란트 아마추어 리그, 발로란트 아마추어 대회, 발로란트 대회, 발로란트 리그, 발로란트 공식 대회, 발로란트 아마추어 대회, 발로란트 레벨업, 라이엇 발로란트, 라이엇 발로란트 대회, 발로란트 대회, 발로란트, League of Legends : Valorant, Valorant, LoL Valorant, Valorant 대회, Valorant 아마추어 대회, Valorant 아마추어 리그, 라이엇 Valorant, 발로란트 커뮤니티 대회, 발로란트 커뮤니티, 라이엇 게임즈 커뮤니티, 게임 커뮤니티, 발로란트 디스코드, FPS 대회, FPS 커뮤니티, FPS 게임 커뮤니티';

export default {
  valEnd: {
    title: '[발로란트] 대회 신청',
    ...createMeta({
      title: '[발로란트] 대회 신청',
      description:
        '모두가 즐기는 VAL 아마추어 리그 \n'
        + '다양한 대회와 커뮤니티가 기다립니다!',
      keywords,
      image,
    }),
  },
  newsFeed: {
    title: '[발로란트] 공지/이벤트',
    ...createMeta({
      title: '[발로란트] 공지/이벤트',
      description: '대회 관련 공지 및 이벤트 내용을 확인해 보세요.',
      keywords,
      image,
    }),
  },
};
