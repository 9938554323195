import { createMeta } from '@/data/meta/utils';

const image = '/img/pages/end/meta/WR_meta_guide.jpg';
const keywords = '라이엇 게임즈 아마추어 리그, 라이엇 아마추어 리그, 레벨업 라이엇, 레벨업 롤, 레벨업 LoL, 레벨업 라이엇게임즈, 롤 아마추어리그, 리그오브레전드 아마추어 리그, 롤 아마추어, e스포츠 아마추어 대회, 이스포츠 아마추어 대회, 아마추어 대회, 레벨업 대학, League of Legends, 와일드리프트 아마추어 리그, 와일드리프트 아마추어 대회, 와일드리프트 대회, 와일드리프트 리그, 와일드 리프트 공식 대회, 와일드 리프트 아마추어 대회, 와일드 리프트 레벨업, 라이엇 와일드리프트, 라이엇 와일드리프트 대회, 와일드 리프트, League of Legends : WildRift, WildRift, LoL WildRift, WildRift 대회, WildRift 아마추어 대회, WildRift 아마추어 리그, 와리 대회, 와일드 리프트 커뮤니티 대회, 와일드 리프트 커뮤니티, 와리 커뮤니티, 라이엇 게임즈 커뮤니티, 게임 커뮤니티, 와일드 리프트 디스코드, 와리 디스코드';

export default {
  wrEnd: {
    title: '[와일드 리프트] 대회 신청',
    ...createMeta({
      title: '[와일드 리프트] 대회 안내',
      description:
        '모두가 즐기는 WR 아마추어 리그 \n'
        + '다양한 대회와 커뮤니티가 기다립니다!',
      keywords,
      image,
    }),
  },
  newsFeed: {
    title: '[와일드 리프트] 공지/이벤트',
    ...createMeta({
      title: '[와일드 리프트] 공지/이벤트',
      description: '대회 관련 공지 및 이벤트 내용을 확인해 보세요.',
      keywords,
      image,
    }),
  },
};
