import lolacademiaMeta from '@/data/meta/gamesBy/lolacademiaMeta';
import loldocupMeta from '@/data/meta/gamesBy/loldocubMeta';
import wariwariMeta from '@/data/meta/gamesBy/wariwariMeta';
import shotValMeta from '@/data/meta/gamesBy/shotValMeta';
import weeklycupMeta from '@/data/meta/gamesBy/weeklycupMeta';
import tftMeta from '@/data/meta/endGamesBy/tftMeta';
import lolMeta from '@/data/meta/endGamesBy/lolMeta';
import wrMeta from '@/data/meta/endGamesBy/wrMeta';
import valMeta from '@/data/meta/endGamesBy/valMeta';

export default {
  lol_donation: { ...loldocupMeta },
  wr_wariwari: { ...wariwariMeta },
  val_shotval: { ...shotValMeta },
  lol_academia: { ...lolacademiaMeta },
  tft_weeklycup: { ...weeklycupMeta },
  tft: { ...tftMeta },
  lol: { ...lolMeta },
  wr: { ...wrMeta },
  val: { ...valMeta },
};
