import { createMeta } from '@/data/meta/utils';

const image = '/img/asset/TFT_metainfo.jpg';
const keywords = '라이엇 게임즈 아마추어 리그, 라이엇 아마추어 리그, 레벨업 라이엇, 레벨업 , 레벨업 TFT, 레벨업 라이엇게임즈, 티에프티 아마추어리그, 전략적팀전투 아마추어 리그, 티에프티 아마추어, e스포츠 아마추어 대회, 이스포츠 아마추어 대회, 아마추어 대회, 레벨업 대회, Team Fight Tacricss, 2022 TFT Weekly Cup, 전략적팀전투 아마추어 리그, 전략적팀전투 아마추어 대회, 전략적팀전투 대회, 전략적팀전투 리그, 전략적팀전투 공식 대회, 전략적팀전투 아마추어 대회, 전략적팀전투 레벨업, 라이엇 전략적팀전투, 라이엇 전략적팀전투 대회, 전략적팀전투 위클리 컵, 전략적팀전투 대회, 전략적팀전투, Team Fight Tacricss 대회, TFT, TFT 대회, Team Fight Tacricss 아마추어 대회, TFT 아마추어 대회, Team Fight Tacricss 아마추어 리그, TFT 아마추어 리그, 라이엇 Team Fight Tacrics, 라이엇 공식 대회, 라이엇 공식 아마추어 대회, 라이어 공식 아마추어 리그, 공식 롤 대회, 공식 롤 리그';

export default {
  tftHome: {
    title: '[TFT WEEKLY CUP] 대회 신청',
    ...createMeta({
      title: '[TFT WEEKLY CUP] 대회 신청',
      description: '총 600만원의 상금과 레전드 컵에 진출할 수 있는 기회! TFT 게임을 즐기는 유저를 위한 공식 대회에 참여해 보세요.',
      keywords,
      image,
    }),
  },
  tftLeagueGuide: {
    title: '[TFT WEEKLY CUP] 대회 안내',
    ...createMeta({
      title: '[TFT WEEKLY CUP] 대회 안내',
      description: '총 600만원의 상금과 레전드 컵에 진출할 수 있는 기회! TFT 게임을 즐기는 유저를 위한 공식 대회에 참여해 보세요.',
      keywords,
      image,
    }),
  },
  tftLeagueResult: {
    title: '[TFT WEEKLY CUP] 대회 결과',
    ...createMeta({
      title: '[TFT WEEKLY CUP] 대회 결과',
      description: '대회 결과를 확인해 보세요.',
      keywords,
      image,
    }),
  },
  newsFeed: {
    title: '[TFT WEEKLY CUP] 공지/이벤트',
    ...createMeta({
      title: '[TFT WEEKLY CUP] 공지/이벤트',
      description: '대회 관련 공지 및 이벤트 내용을 확인해 보세요.',
      keywords,
      image,
    }),
  },
  tftEnd: {
    title: '[전략적 팀 전투] 대회 신청',
    ...createMeta({
      title: '[전략적 팀 전투] 대회 신청',
      description: '모두가 즐기는 TFT 아마추어 리그 \n'
        + '다양한 대회와 커뮤니티가 기다립니다!',
      keywords: '라이엇 게임즈 아마추어 리그, 라이엇 아마추어 리그, 레벨업 라이엇, 레벨업 , 레벨업 TFT, 레벨업 라이엇게임즈, 티에프티 아마추어리그, 전략적팀전투 아마추어 리그, 티에프티 아마추어, e스포츠 아마추어 대회, 이스포츠 아마추어 대회, 아마추어 대회, 레벨업 대회, 전략적팀전투 아마추어 리그, 전략적팀전투 아마추어 대회, 전략적팀전투 대회, 전략적팀전투 리그, 전략적팀전투 공식 대회, 전략적팀전투 아마추어 대회, 전략적팀전투 레벨업, 라이엇 전략적팀전투, 라이엇 전략적팀전투 대회, 전략적팀전투 대회, 전략적팀전투, Team Fight Tacricss 대회, TFT, TFT 대회, Team Fight Tacricss 아마추어 대회, TFT 아마추어 대회, Team Fight Tacricss 아마추어 리그, TFT 아마추어 리그, 라이엇 Team Fight Tacrics, 라이엇 공식 대회, 라이엇 공식 아마추어 대회, 라이엇 공식 아마추어 리그, 공식 롤 대회, 공식 롤 리그, 롤체, 롤토체스, 롤체 대회, 롤토체스 대회, 롤체 커뮤니티, 롤토체스 커뮤니티, 롤체 디스코드, 롤토체스 디스코드, TFT 커뮤니티 대회, TFT 커뮤니티, 라이엇 게임즈 커뮤니티, 게임 커뮤니티, TFT 디스코드',
      image: '',
    }),
  },
};
