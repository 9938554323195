import { createMeta } from '@/data/meta/utils';

const image = '/img/pages/end/meta/LoL_meta_guide.jpg';
const keywords = '라이엇 게임즈 아마추어 리그, 라이엇 아마추어 리그, 레벨업 라이엇, 레벨업 , 레벨업 LoL, 레벨업 라이엇게임즈, 롤 아마추어리그, 리그오브레전드 아마추어 리그, 롤 아마추어, e스포츠 아마추어 대회, 이스포츠 아마추어 대회, 아마추어 대회, 레벨업 대회, League of Legends, 리그오브레전드 아마추어 리그, 리그오브레전드 아마추어 대회, 리그오브레전드 대회, 리그오브레전드 리그, 리그오브레전드 공식 대회, 리그오브레전드 아마추어 대회, 리그오브레전드 레벨업, 라이엇 리그오브레전드, 라이엇 리그오브레전드 대회, 리그오브레전드 대회, 리그오브레전드, League of Legends 대회, LoL, LoL 대회,  League of Legends 아마추어 대회, LoL 아마추어 대회, League of Legends 아마추어 리그, LoL 아마추어 리그, 라이엇 League of Legends, 라이엇 공식 대회, 라이엇 공식 아마추어 대회, 라이어 공식 아마추어 리그, 공식 롤 대회, 공식 롤 리그, 롤 대회, 롤 커뮤니티 대회, 롤 커뮤니티, 라이엇 게임즈 커뮤니티, 게임 커뮤니티, 롤 디스코드';

export default {
  lolEnd: {
    title: '[리그 오브 레전드] 대회 신청',
    ...createMeta({
      title: '[리그 오브 레전드] 대회 신청',
      description: '모두가 즐기는 LOL 아마추어 리그 \n'
        + '다양한 대회와 커뮤니티가 기다립니다!',
      keywords,
      image,
    }),
  },
  newsFeed: {
    title: '[리그 오브 레전드] 공지/이벤트',
    ...createMeta({
      title: '[리그 오브 레전드] 공지/이벤트',
      description: '대회 관련 공지 및 이벤트 내용을 확인해 보세요.',
      keywords,
      image,
    }),
  },
};
