import { createMeta } from '@/data/meta/utils';

const keywords = '라이엇 대학대회 라이엇 게임즈 아마추어 리그, 레벨업 라이엇, 레벨업 롤, 레벨업 LoL, 레벨업 라이엇게임즈, 롤 대학리그, 라이엇 아마추어리그, 롤 아마추어리그, LOL 대학리그, 리그오브레전드 대학리그, 배틀 아카데미아, 롤 대학대회, 라이엇 아마추어, 롤 아마추어, 배틀 아카데미, 대학 배틀, 전투사관학교 대회, LoL 대학리그, 대학리그, 이스포츠 대학리그, e스포츠 대학리그, 이스포츠 대학대회, 롤 아마추어 대회, 롤 대학, 롤대학, 롤 대회, 아마추어 대회, 레벨업 대학, 2021 LoL 대학리그 배틀 아카데미아, 롤 공식대회, 아마추어 공식대회, 공식 아마추어 대회, 공식 대학리그, 공식 대학대회, 공식 롤대회, 레벨업 공식';
const image = '/img/asset/BattleAcademia_SEO.jpg';

export default {
  lolAcademiaHome: {
    title: '[배틀 아카데미아] 대회 신청',
    ...createMeta({
      title: '[배틀 아카데미아] 대회 신청',
      description:
        '2021 LoL 대학리그 배틀 아카데미아와 다양한 커뮤니티 대회까지 참여해 보세요.',
      keywords,
      image,
    }),
  },
  LolAcademiaLeagueResult: {
    title: '[배틀 아카데미아] 대회 결과',
    ...createMeta({
      title: '[배틀 아카데미아] 대회 결과',
      description: '공식 대회 랭킹 및 레벨업 랭킹(MMR) 정보를 확인해 보세요.',
      keywords,
      image,
    }),
  },
  newsFeed: {
    title: '[배틀 아카데미아] 공지/이벤트',
    ...createMeta({
      title: '[배틀 아카데미아] 공지/이벤트',
      description: '대회 관련 공지 및 이벤트 내용을 확인해 보세요.',
      keywords,
      image,
    }),
  },
  AcademyLanding: {
    title: '[배틀 아카데미아] 대회 안내',
    ...createMeta({
      title: '[배틀 아카데미아] 대회 안내',
      description:
        '2021 LoL 대학리그 배틀 아카데미아에서 파이널 토너먼트, 상금 500만원과 트로피를 거머쥘 수 있는 기회를 잡아보세요.',
      keywords,
      image: '/img/asset/BattleAcademia_SEO.jpg',
    }),
  },
};
