import { createMeta } from '@/data/meta/utils';

const image = '/img/asset/shotVal_seo.jpg';
const keywords = '라이엇 게임즈 아마추어 리그, 라이엇 아마추어 리그, 레벨업 라이엇, 레벨업 , 레벨업 LoL, 레벨업 라이엇게임즈, 롤 아마추어리그, 리그오브레전드 아마추어 리그, 롤 아마추어, e스포츠 아마추어 대회, 이스포츠 아마추어 대회, 아마추어 대회, 레벨업 대회, League of Legends, 2021 전국 샷VAL 자랑, 발로란트 아마추어 리그, 발로란트 아마추어 대회, 발로란트 대회, 발로란트 리그, 발로란트 공식 대회, 발로란트 아마추어 대회, 발로란트 레벨업, 라이엇 발로란트, 라이엇 발로란트 대회, 발로란트 전국 샷VAL 자랑, 발로란트 대회, 발로란트, League of Legends : Valorant, Valorant, LoL Valorant, Valorant 대회, Valorant 아마추어 대회, Valorant 아마추어 리그, 라이엇 Valorant';

export default {
  valorantHome: {
    title: '[전국 샷VAL 자랑] 대회 신청',
    ...createMeta({
      title: '[전국 샷VAL 자랑] 대회 신청',
      description:
        '참가만 해도 상품이 쏟아지는 모든 발로란트 유저들의 대축제에 참여해 보세요.',
      keywords,
      image,
    }),
  },
  ShotValLeagueResult: {
    title: '[전국 샷VAL 자랑] 대회 결과',
    ...createMeta({
      title: '[전국 샷VAL 자랑] 대회 결과',
      description: '대회 결과를 확인해 보세요.',
      keywords,
      image,
    }),
  },
  newsFeed: {
    title: '[전국 샷VAL 자랑] 공지/이벤트',
    ...createMeta({
      title: '[전국 샷VAL 자랑] 공지/이벤트',
      description: '대회 관련 공지 및 이벤트 내용을 확인해 보세요.',
      keywords,
      image,
    }),
  },
  ShotValLanding: {
    title: '[전국 샷VAL 자랑] 대회 안내',
    ...createMeta({
      title: '[전국 샷VAL 자랑] 대회 안내',
      description:
        '총 상금 2,500만원! 저티어부터 고티어, 인플루언서 그리고 프로까지 모두를 하나로 잇는  발로란트 유저들의 대축제에 참여해 보세요.',
      keywords,
      image,
    }),
  },
};
